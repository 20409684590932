// Generated by Framer (83eb5d8)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Button from "./EBCBRAkbd";
import YouTube from "./Pbzs9i0VU";
import X from "./RXtuUTFcW";
const XFonts = getFonts(X);
const YouTubeFonts = getFonts(YouTube);
const ButtonFonts = getFonts(Button);

const cycleOrder = ["qJEsshwrm"];

const serializationHash = "framer-qpBDn"

const variantClassNames = {qJEsshwrm: "framer-v-3xusdp"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transition1 = {damping: 30, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const animation = {opacity: 0.7, rotate: 0, scale: 1, transition: transition1}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({button, buttonLink, height, id, width, x, xLink, youTube, youTubeLink, ...props}) => { return {...props, EDTq6jY9L: youTube ?? props.EDTq6jY9L ?? true, H4H8SZb6k: youTubeLink ?? props.H4H8SZb6k, MJ8qtrgql: button ?? props.MJ8qtrgql ?? true, QXdGd87vx: buttonLink ?? props.QXdGd87vx, tO78A0Ajl: xLink ?? props.tO78A0Ajl, yH1X2ugXU: x ?? props.yH1X2ugXU ?? true} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;button?: boolean;youTube?: boolean;x?: boolean;buttonLink?: string;youTubeLink?: string;xLink?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, MJ8qtrgql, EDTq6jY9L, yH1X2ugXU, QXdGd87vx, H4H8SZb6k, tO78A0Ajl, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "qJEsshwrm", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-3xusdp", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"qJEsshwrm"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}>{yH1X2ugXU && (<motion.div className={"framer-1zlutl-container"} layoutDependency={layoutDependency} layoutId={"hANYcqh46-container"} whileHover={animation}><X BmMSDrvv6 height={"100%"} id={"hANYcqh46"} layoutId={"hANYcqh46"} LPnlOMLdE={tO78A0Ajl} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div>)}{EDTq6jY9L && (<motion.div className={"framer-146oqc3-container"} layoutDependency={layoutDependency} layoutId={"FzuE_vVEc-container"} whileHover={animation}><YouTube CbAhf7Qu7={H4H8SZb6k} height={"100%"} id={"FzuE_vVEc"} layoutId={"FzuE_vVEc"} style={{height: "100%", width: "100%"}} width={"100%"} Z1Cc2XVZF/></motion.div>)}{MJ8qtrgql && (<motion.div className={"framer-1436b59-container"} layoutDependency={layoutDependency} layoutId={"Ts2Upkp5N-container"}><Button aw9hCqzCY={"Get In Touch"} dC0tSoC9T={-90} FsJQLC6Zo={false} FV9q84oBp={QXdGd87vx} height={"100%"} id={"Ts2Upkp5N"} layoutId={"Ts2Upkp5N"} qUWpVfITp={false} Trlo9yZQE={"At"} variant={"MHgdijXgI"} width={"100%"}/></motion.div>)}</motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-qpBDn[data-border=\"true\"]::after, .framer-qpBDn [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-qpBDn.framer-1aataa9, .framer-qpBDn .framer-1aataa9 { display: block; }", ".framer-qpBDn.framer-3xusdp { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-qpBDn .framer-1zlutl-container, .framer-qpBDn .framer-146oqc3-container { align-self: stretch; flex: none; height: auto; position: relative; width: 42px; }", ".framer-qpBDn .framer-1436b59-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-qpBDn.framer-3xusdp { gap: 0px; } .framer-qpBDn.framer-3xusdp > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-qpBDn.framer-3xusdp > :first-child { margin-left: 0px; } .framer-qpBDn.framer-3xusdp > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 41
 * @framerIntrinsicWidth 227.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"MJ8qtrgql":"button","EDTq6jY9L":"youTube","yH1X2ugXU":"x","QXdGd87vx":"buttonLink","H4H8SZb6k":"youTubeLink","tO78A0Ajl":"xLink"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerxCjjvUH0E: React.ComponentType<Props> = withCSS(Component, css, "framer-qpBDn") as typeof Component;
export default FramerxCjjvUH0E;

FramerxCjjvUH0E.displayName = "Buttons";

FramerxCjjvUH0E.defaultProps = {height: 41, width: 227.5};

addPropertyControls(FramerxCjjvUH0E, {MJ8qtrgql: {defaultValue: true, title: "Button", type: ControlType.Boolean}, EDTq6jY9L: {defaultValue: true, title: "YouTube", type: ControlType.Boolean}, yH1X2ugXU: {defaultValue: true, title: "X", type: ControlType.Boolean}, QXdGd87vx: {title: "Button Link", type: ControlType.Link}, H4H8SZb6k: {title: "YouTube Link", type: ControlType.Link}, tO78A0Ajl: {title: "X Link", type: ControlType.Link}} as any)

addFonts(FramerxCjjvUH0E, [...XFonts, ...YouTubeFonts, ...ButtonFonts])